import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
} from "prop-types";
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { CrossIcon, DocumentIcon, TagIcon } from "components/Icons";
import { photoUrl } from "helpers/helpers";
import { MobileStyledSelect, StyledButton } from "components";

export const RequestWholesaleDrawer = ({
  open,
  handleCloseOrderDirectAccess,
  brandLogo,
  shippingAddress,
  disabledBtn,
  loading,
  handleRequestWholesale,
  classes,
  hideRequestPaymentTerms,
  paymentTermsId,
  handleChangePayterm,
  paymentTerms,
}) => {
  return (
    <Drawer
      anchor="bottom"
      open={open}
      PaperProps={{
        sx: {
          background: "white",
          minHeight: "460px",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        },
      }}
    >
      <Grid container>
        <IconButton
          sx={{
            position: "absolute",
            right: "16px",
            top: "20px",
            bgcolor: "#F6F6F6",
            width: "24px",
            height: "24px",
            p: "4px",
          }}
          onClick={handleCloseOrderDirectAccess}
        >
          <CrossIcon />
        </IconButton>

        <Grid height="64px" item xs={12} />

        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
          item
          xs={12}
        >
          <Box
            sx={{
              width: "208px",
              height: "58px",
              objectFit: "contain",
              borderRadius: "8px",
            }}
            component="img"
            src={photoUrl(brandLogo?.fileName)}
          />
        </Grid>

        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: "8px",
          }}
          item
          xs={12}
        >
          <Typography fontSize={24} fontWeight={500} color="#000">
            {name}
          </Typography>
          <Typography fontSize={12} fontWeight={400} color="#00000080">
            {shippingAddress?.city}, {shippingAddress?.state}
          </Typography>
        </Grid>

        <Grid sx={{ pt: "21px" }} item xs={12} container>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "8px",
              pr: "16px",
              "& svg": {
                mt: "2px",
                transform: "rotate(270deg)",
              },
            }}
            item
            xs={6}
          >
            <TagIcon width={20} height={20} />
            <Typography fontSize={14} fontWeight={700} color="#2F3040">
              Business Pricing
            </Typography>
          </Grid>

          <Grid
            sx={{
              borderLeft: "1px solid #00000010",
              display: "flex",
              justifyContent: "flex-start",
              gap: "8px",
              pl: "12px",
            }}
            item
            xs={6}
          >
            <DocumentIcon />
            <Typography fontSize={14} fontWeight={700} color="#2F3040">
              Easy Reordering
            </Typography>
          </Grid>
        </Grid>

        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          my="26px"
          item
          xs={12}
        >
          {hideRequestPaymentTerms ? null : (
            <MobileStyledSelect
              value={paymentTermsId}
              fullWidth
              formSx={{ maxWidth: "312px" }}
              onChange={(e) => handleChangePayterm(e.target.value)}
            >
              <MenuItem sx={{ display: "none" }} value="">
                Request Payment Terms
              </MenuItem>

              {paymentTerms?.map((term) => (
                <MenuItem
                  key={term.id}
                  value={term.id}
                  sx={{ fontSize: "12px" }}
                >
                  {term.name}
                </MenuItem>
              ))}
            </MobileStyledSelect>
          )}
        </Grid>

        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          item
          xs={12}
        >
          <StyledButton
            disabled={disabledBtn}
            onClick={handleRequestWholesale}
            className={classes.requestButton}
            sx={{ width: "100%", maxWidth: "312px" }}
            variant="contained"
            label={loading ? "Loading..." : "Request Wholesale Access"}
          />
        </Grid>

        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: "20px",
          }}
          item
          xs={12}
        >
          <Typography fontSize={16} fontWeight={500} color="#000">
            Wholesale Made Simple
          </Typography>
        </Grid>
      </Grid>
    </Drawer>
  );
};

RequestWholesaleDrawer.propTypes = {
  open: bool,
  handleCloseOrderDirectAccess: func,
  brandLogo: object,
  shippingAddress: object,
  disabledBtn: bool,
  loading: bool,
  handleRequestWholesale: func,
  classes: object,
  hideRequestPaymentTerms: bool,
  paymentTermsId: oneOfType([string, number]),
  handleChangePayterm: func,
  paymentTerms: array,
};

RequestWholesaleDrawer.defaultProps = {
  open: false,
  handleCloseOrderDirectAccess: () => {},
  brandLogo: {},
  shippingAddress: {},
  disabledBtn: false,
  loading: false,
  handleRequestWholesale: () => {},
  classes: {},
  hideRequestPaymentTerms: false,
  paymentTermsId: "",
  handleChangePayterm: () => {},
  paymentTerms: [],
};

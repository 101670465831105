import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Routes,
  Navigate,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Loader, PrivateRoute } from "components";
import { getTokenFromLocalStorage } from "helpers/auth";
import {
  CodePage,
  LoginPage,
  MasterPage,
  NotFoundPage,
  OrdersPage,
  RegistrationPage,
  ConversationsPage,
  CartPage,
  VendorsPage,
  SupplierProductsPage,
  StepOne,
  StepTwo,
  StepThree,
  ProductsPage,
  ViewOrderPage,
  ResetPasswordPage,
  RestorePasswordPage,
  PaymentsPage,
  TabletAppPromptPage,
} from "pages";
import { setCurrentUser } from "redux/actions/auth";
import { getCurrentUser } from "services/account";
import { ProductDetailsPage } from "pages/ProductsPage";
import NewProfile from "pages/ProfilePage/NewProfile";
import { INVITATION_PATH } from "utils/constants";
import { useMediaDevice } from "hooks/useMediaDevice";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isTablet } = useMediaDevice();

  const { pathname } = useLocation();
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { orderDirect } = currentUser || {};

  useEffect(() => {
    orderDirect?.length === 1
      ? (document.title = `${orderDirect?.[0]?.distributor?.name} - Storefront`)
      : (document.title = "SimplyDepo");
  }, [orderDirect]);

  const distributorName = useMemo(() => {
    if (pathname.split("/")[1] === INVITATION_PATH) {
      return pathname.split("/")[2];
    }
  }, [pathname]);

  const tokenParam = searchParams.get("token");
  const emailParam = searchParams.get("email");
  const stepParam = searchParams.get("step");
  const photoParam = searchParams.get("photo");
  const nameParam = searchParams.get("name");
  const storeNameParam = searchParams.get("storeName");
  const storeParam = searchParams.get("store");

  useEffect(() => {
    const state = {
      tokenParam,
      emailParam,
      stepParam,
      photoParam,
      nameParam,
      storeNameParam,
      distributorName,
      storeParam,
    };

    if (stepParam === "login") return navigate("/login");
    if (stepParam === "registration" || distributorName)
      return navigate("/registration/step-1", { state });
    if (stepParam === "code")
      return navigate("/registration/step-2", { state });
    if (stepParam === "complete")
      return navigate("/registration/step-3", { state });
  }, [
    emailParam,
    nameParam,
    navigate,
    photoParam,
    stepParam,
    storeNameParam,
    tokenParam,
    distributorName,
    storeParam,
  ]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = getTokenFromLocalStorage();

    if (token) {
      getCurrentUser()
        .then((user) => {
          dispatch(setCurrentUser(user));
          setLoading(false);
        })
        .catch(() => setLoading(false));
      return;
    }
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isTablet) return <TabletAppPromptPage />;

  return loading ? (
    <Loader isLoading={loading} />
  ) : (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <MasterPage />
          </PrivateRoute>
        }
      >
        <Route
          index
          element={
            <PrivateRoute>
              <ProductsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/products/:productId"
          element={
            <PrivateRoute>
              <ProductDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <NewProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="products/:distributorId"
          element={
            <PrivateRoute>
              <SupplierProductsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/cart"
          element={
            <PrivateRoute>
              <CartPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/conversations"
          element={
            <PrivateRoute>
              <ConversationsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <PrivateRoute>
              <OrdersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders/:orderId"
          element={
            <PrivateRoute>
              <ViewOrderPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/suppliers"
          element={
            <PrivateRoute>
              <VendorsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payments"
          element={
            <PrivateRoute>
              <PaymentsPage />
            </PrivateRoute>
          }
        />
        <Route path="/preview/:distributor_name" element={<ProductsPage />} />
      </Route>

      <Route path="/login" element={<LoginPage />} />
      <Route path="/login/:distributor_name" element={<LoginPage />} />

      <Route
        path={`/${INVITATION_PATH}/:distributor_name`}
        element={<RegistrationPage />}
      >
        <Route
          path={`/${INVITATION_PATH}/:distributor_name`}
          element={<Navigate to="/registration/step-1" />}
        />
      </Route>

      <Route path="/registration" element={<RegistrationPage />}>
        <Route
          path="/registration"
          element={<Navigate to="/registration/step-1" />}
        />
        <Route path="/registration/step-1" element={<StepOne />} />
        <Route path="/registration/step-2" element={<StepTwo />} />
        <Route path="/registration/step-3" element={<StepThree />} />
      </Route>

      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route
        path="/reset-password/:distributor_name"
        element={<ResetPasswordPage />}
      />
      {/* <Route path="/confirm-password" element={<ConfirmPasswordPage />} /> */}
      <Route path="/restore-password" element={<RestorePasswordPage />} />
      <Route path="/confirm-code" element={<CodePage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;

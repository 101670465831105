import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialog: {
    maxWidth: "631px",
  },

  titleText: {
    fontSize: "17px",
    color: "#3F3F3F",
  },

  rowTitle: {
    fontSize: "11px",
    color: "#000000",
    marginTop: "0px",
  },

  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #d5d9d9",
    alignItems: "center",
    padding: "10px 22px 12px 32px",
  },

  dialogContent: {
    width: "631px",
    minWidth: "631px",
    padding: "20px 41px 0px 42px!important",
  },

  dialogActions: {
    // padding: "30px 47px 35px",
  },

  fileRow: {
    display: "flex",
    backgroundColor: "#eeeeee",
    justifyContent: "space-between",
    padding: "3px 7px 4px 10px",
    borderRadius: "4px",
    maxWidth: "198px",
  },

  fileTitle: { display: "flex", gap: "6px", width: "85%" },

  filesAdornment: {
    width: "70%",
    paddingTop: "10px",
  },

  filesWrapper: {
    display: "flex",
    gap: "5px",
    width: "100%",
    maxWidth: "644px",
    overflow: "auto",
    paddingTop: "5px",
    minHeight: "34px",
    "&::-webkit-scrollbar": {
      height: "5px",
    },
  },

  orderTitleWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },

  recipientsTable: {
    height: "100%",
    zIndex: "10",
    border: { xs: "none", sm: "1px solid #D5D9D9" },
    boxShadow: {
      xs: "0px 4px 14px rgba(0, 0, 0, 0.10) !important",
      sm: "none",
    },
    width: "100%",
    borderTop: "none",
    backgroundColor: "#ffffff",
    overflow: "overlay",
    borderRadius: "0 0 4px 4px",
  },

  recipientChipWrapper: {
    maxHeight: "75px",
    overflow: "auto",
  },

  recipientItem: {
    width: "100%",
    cursor: "pointer",
    height: "45px",
    "&:not(:last-of-type)": {
      borderBottom: "1px solid #D5D9D9",
    },
    alignItems: "center",
    paddingLeft: "13px",
    "& .MuiTypography-root": {
      color: "#1C1C19",
      fontSize: "12px",
    },
  },

  rowWrap: {
    display: "flex",
  },

  textfield: {
    color: "#ff0000",
    "& input::placeholder, & textarea::placeholder": {
      color: "#BEBEBE",
      opacity: 1,
      fontWeight: 400,
    },
  },

  successWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "400px",
  },

  activeIcon: {
    "& path": {
      strokeDasharray: 23,
      strokeDashoffset: 23,
      animation: "$draw 0.5s linear forwards",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    },
  },

  "@keyframes draw": {
    "100%": {
      strokeDashoffset: 0,
    },
  },

  logoWrap: {
    width: "92px",
    height: "92px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "0.5px solid #D5D9D9",
    borderRadius: "8px",
    marginTop: "31px",
  },

  logo: {
    width: "100%",
    height: "100%",
  },
}));

export default useStyles;

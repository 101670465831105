import * as Yup from "yup";
import { PAY_WITH } from "./CartPage.constants";

export const validationSchema = ({
  isAdvancePayment,
  isMobile,
  userCartStep,
}) =>
  Yup.object().shape({
    contactId: Yup.number()
      .typeError("Provide delivery contact")
      .required("Provide delivery contact"),
    cardId: Yup.string().when("payWith", {
      is: PAY_WITH.CREDIT,
      then: Yup.string().trim().required("Provide payment method"),
      otherwise:
        isAdvancePayment &&
        Yup.string().trim().required("Provide payment method"),
    }),
    products: Yup.array()
      .of(
        Yup.object().shape({
          quantity: Yup.number()
            .typeError("Must be a number.")
            .when("parentProduct", {
              is: null,
              otherwise: Yup.number().when(
                "parentProduct",
                (parentProduct, schema) => {
                  return schema.test({
                    test: (quantity) =>
                      quantity >= (parentProduct?.minOrderQTY || 0),
                    message: "Quantity should be greater than MOQ",
                  });
                }
              ),
            })
            .when("product", {
              is: null,
              otherwise: Yup.number().when("product", (product, schema) => {
                return schema.test({
                  test: (quantity) =>
                    quantity >=
                    (product?.minOrderQTY ||
                      product?.product?.minOrderQTY ||
                      product?.parentProduct?.minOrderQTY ||
                      product?.product?.parentProduct?.minOrderQTY ||
                      0),
                  message: "Quantity should be greater than MOQ",
                });
              }),
            })
            .when("minOrderQTY", (minOrderQTY, schema) => {
              return schema.test({
                test: (quantity) => quantity >= (minOrderQTY || 0),
                message: "Quantity should be greater than MOQ",
              });
            }),
          itemDiscountAmount: Yup.number()
            .required("This field is required")
            .typeError("Must be a number.")
            .when("itemDiscountType", {
              is: "PERCENTAGE",
              then: Yup.number().test(
                "discountValidation",
                "Please type a valid discount.",
                (value) => {
                  return value >= 0 && value <= 100;
                }
              ),
            }),
        })
      )
      .test("productsValidation", "At least 1 product is required", (value) => {
        if (isMobile && userCartStep === 1) return true;
        return value.length > 0;
      }),
  });

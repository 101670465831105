import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { bool, func, number, object } from "prop-types";
import { useMemo, useState } from "react";
import { CrossIcon } from "../../../../../../components/Icons";
import { TABS } from "./ContactDialog.constants";
import useStyles from "./styles";
import {
  ContactTab,
  LocationsTab,
  SummaryTab,
  CarriersTab,
} from "./components";

const ContactDialog = ({
  open,
  onClose,
  supplier,
  showContactOnly,
  defaultTab,
}) => {
  const [tabsValue, setTabsValue] = useState(defaultTab ?? 3);
  const classes = useStyles();

  const renderContent = useMemo(() => {
    switch (tabsValue) {
      case 0: {
        return <SummaryTab {...{ supplier }} />;
      }
      case 1: {
        return <LocationsTab {...{ supplier }} />;
      }
      case 2: {
        return <CarriersTab shippingCarriers={supplier?.shippingCarriers} />;
      }
      case 3: {
        return <ContactTab {...{ supplier }} />;
      }
    }
  }, [tabsValue, supplier]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{ className: classes.dialogWrapper }}
    >
      <DialogTitle className={classes.titleWrapper}>
        <Typography>{supplier?.name}</Typography>
        <IconButton onClick={onClose}>
          <CrossIcon size={38} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        {!showContactOnly && (
          <Tabs
            sx={{ pl: "45px" }}
            value={tabsValue}
            onChange={(e, newVal) => setTabsValue(newVal)}
          >
            {TABS.map((tab, i) => (
              <Tab
                key={tab}
                value={i}
                style={{ pointerEvents: "auto" }}
                label={<span>{tab}</span>}
                className={classes.tab}
              />
            ))}
          </Tabs>
        )}
        <Divider />
        <Box p="20px 24px 20px 32px" height="100%">
          {renderContent}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ContactDialog.propTypes = {
  open: bool,
  onClose: func,
  supplier: object,
  showContactOnly: bool,
  defaultTab: number,
};

export default ContactDialog;

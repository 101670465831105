import { useMemo } from "react";
import useStyles from "./styles";
import { useRequestOrderDirect } from "./RequestWholesale.hooks";
import { useMediaDevice } from "hooks/useMediaDevice";
import { RequestWholesaleDrawer } from "./RequestWholesaleDrawer/RequestWholesaleDrawer";
import { RequestWholesaleDialog } from "./RequestWholesaleDialog/RequestWholesaleDialog";

const RequestWholesale = () => {
  const { isMobile } = useMediaDevice();
  const classes = useStyles({ isMobile });

  const {
    showRequestDialog,
    handleCloseOrderDirectAccess,
    currentStoreData,
    handleChangePayterm,
    paymentTermsId,
    handleRequestWholesale,
    loading,
  } = useRequestOrderDirect();

  const {
    brandLogo,
    name,
    shippingAddress,
    paymentTerms,
    hideRequestPaymentTerms,
  } = useMemo(() => currentStoreData || {}, [currentStoreData]);

  const disabledBtn = useMemo(() => {
    if (loading) return true;

    if (hideRequestPaymentTerms) return false;

    if (!paymentTermsId) return true;
  }, [hideRequestPaymentTerms, loading, paymentTermsId]);

  return isMobile ? (
    <RequestWholesaleDrawer
      open={showRequestDialog}
      {...{
        handleCloseOrderDirectAccess,
        brandLogo,
        shippingAddress,
        disabledBtn,
        loading,
        handleRequestWholesale,
        classes,
        hideRequestPaymentTerms,
        paymentTermsId,
        handleChangePayterm,
        paymentTerms,
      }}
    />
  ) : (
    <RequestWholesaleDialog
      open={showRequestDialog}
      {...{
        handleCloseOrderDirectAccess,
        classes,
        brandLogo,
        shippingAddress,
        hideRequestPaymentTerms,
        paymentTermsId,
        handleChangePayterm,
        paymentTerms,
        disabledBtn,
        handleRequestWholesale,
        loading,
        name,
      }}
    />
  );
};

export default RequestWholesale;

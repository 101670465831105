import { Box, Button } from "@mui/material";
import {
  DividerBlock,
  SupplierNameBlock,
  TitleBlock,
  TotalBlock,
  WarningMsgBlock,
} from "./components";
import { useContext, useMemo, useState } from "react";
import { CartContext } from "pages/CartPage/CartPage";
import { CART_FORM } from "pages/CartPage/CartPage.constants";
import { separateNumWithComma } from "helpers/helpers";

export const CartSummaryBlock = () => {
  const {
    cartData,
    grandTotal,
    products,
    calculatedTotal,
    casesCount,
    formField,
    draftsLoading,
    disabledCreateOrderBtn,
    contacts,
  } = useContext(CartContext);
  const { selectedDistributor } = useMemo(() => cartData, [cartData]);

  const { contactId } = formField || {};

  const [showWarning, setShowWarning] = useState(false);

  const disabledPlaceOrderBtn = useMemo(() => {
    return (
      (selectedDistributor?.minimumOrderValue &&
        calculatedTotal < selectedDistributor?.minimumOrderValueAmount) ||
      !formField.products?.length ||
      draftsLoading ||
      !contacts ||
      !contacts?.length ||
      disabledCreateOrderBtn
    );
  }, [
    calculatedTotal,
    contacts,
    disabledCreateOrderBtn,
    draftsLoading,
    formField.products?.length,
    selectedDistributor?.minimumOrderValue,
    selectedDistributor?.minimumOrderValueAmount,
  ]);

  return (
    <Box
      sx={{
        border: "1px solid #D5D9D9",
        borderRadius: "4px",
        pt: 2,
        pb: 5.3,
        px: 5.75,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box display="flex" flexDirection="column">
        <TitleBlock />
        <DividerBlock />
        <SupplierNameBlock name={selectedDistributor?.name} />

        {/* Show the warning if a contact isn't selected */}
        {!cartData.loadingProducts && (
          <>
            <WarningMsgBlock
              show={!contactId}
              text="Please add your contact to proceed"
              sum={selectedDistributor?.minimumOrderValueAmount}
              stylesWrapper={{
                borderColor: "#E21D38",
                bgcolor: "#E21D3810",
              }}
              colorIcon="#E21D38"
            />

            <WarningMsgBlock
              show={
                selectedDistributor?.minimumOrderValue &&
                showWarning &&
                calculatedTotal < selectedDistributor?.minimumOrderValueAmount
              }
              sum={selectedDistributor?.minimumOrderValueAmount}
            />
          </>
        )}
      </Box>

      <Box sx={{ mt: 5 }}>
        <TotalBlock
          itemsCount={casesCount}
          sum={
            products?.length
              ? separateNumWithComma(grandTotal.toFixed(2))
              : "0.00"
          }
        />
        <Box
          onMouseEnter={() => setShowWarning(true)}
          onMouseLeave={() => setShowWarning(false)}
        >
          <Button
            sx={{ height: "43px", fontSize: 15 }}
            fullWidth
            variant="contained"
            // component={Link}
            type="submit"
            form={CART_FORM}
            // to="/orders/1001"
            // state={{ type: "order_confirmation" }}

            disabled={disabledPlaceOrderBtn}
          >
            {draftsLoading ? "Loading..." : "Place Your Order"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

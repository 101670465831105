import { createContext, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useDebounce } from "simply-depo-ui-components";
import {
  getProductsByDistributorService,
  getProductsService,
} from "services/products";
import { paginationSelector } from "redux/selectors/settings";
import { setPaginationAction } from "redux/actions/settings";
import { error } from "helpers/notifications";
import { useShowProductsWithoutLogIn } from "helpers/hooks";
import { useMediaDevice } from "hooks/useMediaDevice";

const selector = createSelector(paginationSelector, (pagination) => ({
  pagination,
}));

export const ProductsContext = createContext();

export const useProducts = ({
  chosenCategoryId,
  searchVal,
  sortingVal,
  searchQuery,
  distributorId,
}) => {
  const { pagination } = useSelector(selector);
  const dispatch = useDispatch();
  const { isMobile } = useMediaDevice();

  const { isShowProductsWithoutLogIn } = useShowProductsWithoutLogIn();

  const [productsState, setProductsState] = useState({
    loading: false,
    list: [],
    count: 0,
  });

  const searchValDebounced = useDebounce(searchVal, 500);

  const handleShowLoading = (show) =>
    setProductsState((prev) => ({ ...prev, loading: show }));

  const handleSearch = useCallback(
    (page) => {
      if (isShowProductsWithoutLogIn && !distributorId) return;

      if (!page) handleShowLoading(true);

      const params = {
        page: page || pagination.currentPage,
        limit: isMobile ? 14 : 50,
      };

      const currentCategory = searchQuery.category_id || chosenCategoryId;

      const preparedParams = {
        ...params,
        ...searchQuery,
        ...sortingVal,
        search: searchValDebounced,
        distributor_ids:
          !isShowProductsWithoutLogIn &&
          (searchQuery.distributor_ids.length || distributorId)
            ? JSON.stringify(
                searchQuery.distributor_ids?.length
                  ? searchQuery.distributor_ids
                  : [distributorId]
              )
            : undefined,
        manufacturer_ids: searchQuery.manufacturer_ids.length
          ? JSON.stringify(searchQuery.manufacturer_ids)
          : undefined,
        category_id: currentCategory ? currentCategory : undefined,
      };

      const currentGetService = isShowProductsWithoutLogIn
        ? getProductsByDistributorService({
            params: preparedParams,
            distributorId,
          })
        : getProductsService({ ...preparedParams });

      currentGetService
        .then((res) => {
          const list = res?.rows ? res?.rows : [];
          setProductsState((prev) => ({
            ...prev,
            loading: false,
            list: page ? [...prev.list, ...list] : list,
            count: res.count ?? 0,
          }));
          dispatch(
            setPaginationAction({
              allPages: Math.ceil(res?.count / 50),
            })
          );
        })
        .catch((err) => {
          handleShowLoading(false);
          error(err?.response?.data?.message);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      searchValDebounced,
      searchQuery,
      dispatch,
      pagination.currentPage,
      sortingVal,
      chosenCategoryId,
      distributorId,
      isMobile,
    ]
  );

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  return {
    productsState,
    handleSearch,
  };
};

import { Box } from "@mui/material";
import { array, func } from "prop-types";
import { SettingsBlock } from "../../../../../../components";
import { MINIMUM_FILTERS } from "../../../../../ProductsPage/ProductsPage.constants";
import {
  INDUSTRIES,
  ORDER_DIRECT_FILTERS,
} from "../../../../VendorsPage.constants";

export const VendorsFilter = ({
  switchOrderDirectParams,
  switchShowMinimumParams,
  switchIndustryParams,
  checkedIndustries,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap="22px" width="292px">
      <SettingsBlock
        title="Order Direct"
        items={ORDER_DIRECT_FILTERS}
        handleChange={(value) => switchOrderDirectParams(value)}
      />
      <SettingsBlock
        title="Minimum Order"
        items={MINIMUM_FILTERS}
        handleChange={(value) => switchShowMinimumParams(value)}
      />
      <SettingsBlock
        multiselect
        title="Industry"
        items={INDUSTRIES}
        checkedSupplierIds={checkedIndustries}
        handleChange={(value) => switchIndustryParams(value)}
        hideCounter
      />
    </Box>
  );
};

VendorsFilter.propTypes = {
  switchOrderDirectParams: func,
  switchShowMinimumParams: func,
  switchIndustryParams: func,
  checkedIndustries: array,
};
VendorsFilter.defaultProps = {
  switchOrderDirectParams: () => {},
  switchShowMinimumParams: () => {},
  switchIndustryParams: () => {},
  checkedIndustries: [],
};

export default VendorsFilter;

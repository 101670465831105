import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { any, string } from "prop-types";

const SectionItem = ({ title, value, justifyContent, titleWidth }) => {
  return (
    <Box
      display="flex"
      justifyContent={justifyContent || "space-between"}
      height="52px"
      alignItems="center"
      mx="24px"
      sx={{
        "&:not(:last-of-type)": {
          borderBottom: "1px solid #D5D9D9",
        },
      }}
    >
      <Typography
        color="#979797"
        fontSize="14px"
        fontWeight="600"
        width={titleWidth}
      >
        {title}
      </Typography>
      <Typography
        fontSize="14px"
        fontWeight={500}
        whiteSpace="pre-wrap"
        textAlign="right"
        color="#000"
      >
        {value}
      </Typography>
    </Box>
  );
};

SectionItem.propTypes = {
  title: string,
  justifyContent: string,
  value: any,
  titleWidth: string,
};

export default SectionItem;

import {
  DELETE_DRAFT,
  GET_DRAFTS_LIST,
  SET_DRAFTS_LOADING,
  UPDATE_DRAFTS_LIST,
  SET_USER_CART_STEP,
  SELECTED_DISTRIBUTOR,
} from "redux/actions/drafts";

const initialState = {
  drafts: [],
  loading: true,
  initialLoaded: false,
  userCartStep: 1,
  selectedDistributor: null,
};

const draftsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DRAFTS_LIST:
      return { ...state, drafts: [...payload], initialLoaded: true };

    case UPDATE_DRAFTS_LIST: {
      const drafts = [...state.drafts];
      const sliceId = payload.oldDraftId || payload.draftData.id;
      if (!sliceId) return { ...state, drafts };

      const spliceIndex = drafts.findIndex((d) => d.id === sliceId);

      drafts.splice(spliceIndex, 1, {
        ...payload.draftData,
        distributor: drafts[spliceIndex].distributor,
      });
      return { ...state, drafts };
    }

    case DELETE_DRAFT: {
      const drafts = [...state.drafts];
      const spliceIndex = drafts.findIndex((d) => d.id === payload);
      drafts.splice(spliceIndex, 1);

      return { ...state, drafts };
    }

    case SELECTED_DISTRIBUTOR:
      return { ...state, selectedDistributor: payload };

    case SET_DRAFTS_LOADING:
      return { ...state, loading: payload };

    case SET_USER_CART_STEP:
      return { ...state, userCartStep: payload };

    default:
      return state;
  }
};

export default draftsReducer;

import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { object } from "prop-types";
import SectionHeader from "../SectionHeader";
import SectionItem from "../SectionItem";
import { normalizeUppercaseString } from "helpers/helpers";

const SummaryTab = ({ supplier }) => {
  const hasOrderDirectApproved = useMemo(() => {
    return supplier?.orderDirect?.[0]?.status === "APPROVED";
  }, [supplier?.orderDirect]);

  const orderDirect = useMemo(() => {
    return supplier?.orderDirect?.[0];
  }, [supplier?.orderDirect]);

  const hasOrderDirectRejected = useMemo(() => {
    return supplier?.orderDirect?.[0]?.status === "REJECTED";
  }, [supplier?.orderDirect]);

  const showPaymentTermsRequested = useMemo(() => {
    if (
      !orderDirect?.hideRequestPaymentTerms &&
      orderDirect?.paymentTermsDuplicate
    ) {
      return true;
    }

    return false;
  }, [
    orderDirect?.hideRequestPaymentTerms,
    orderDirect?.paymentTermsDuplicate,
  ]);

  return (
    <Box display="flex" gap="29px">
      <Box flex="1 1 50%">
        <Box>
          <SectionHeader title="Details" />
          <SectionItem title="Supplier Name" value={supplier?.name} />
          <SectionItem title="ID" value={supplier?.digitId} />
          <SectionItem title="Industry" value={supplier?.companyIndustry} />
          <SectionItem
            title="Minimum Order"
            value={
              !supplier?.minimumOrderValue ||
              supplier?.minimumOrderValueAmount === 0
                ? "No minimum"
                : `$${supplier?.minimumOrderValueAmount}`
            }
          />
          <SectionItem
            title="Total Products"
            value={supplier?._count?.products}
          />
        </Box>
        <Box>
          <SectionHeader title="Contacts" />
          <Box maxHeight="156px" overflow="auto">
            {supplier?.contacts?.length ? (
              supplier?.contacts?.map((contact) => (
                <SectionItem
                  key={contact?.id}
                  title={contact?.role}
                  value={`${contact?.name}\n${contact?.email}`}
                />
              ))
            ) : (
              <SectionItem title="No contacts yet" />
            )}
          </Box>
        </Box>
      </Box>
      <Box flex="1 1 50%">
        <Box>
          <SectionHeader title="Connection" />
          <SectionItem
            title="Order Direct"
            value={
              normalizeUppercaseString(supplier?.orderDirect?.[0]?.status) ||
              "-"
            }
          />
        </Box>

        {(hasOrderDirectApproved || hasOrderDirectRejected) && (
          <Box>
            <SectionHeader title="Settings" />

            {showPaymentTermsRequested && (
              <SectionItem
                title="Payment Terms Requested"
                value={orderDirect.paymentTermsDuplicate?.name || "-"}
              />
            )}

            <SectionItem
              title="Payment Terms Approved"
              value={
                supplier?.orderDirect?.[0]?.approvedPaymentTermsDuplicate
                  ?.name ?? "-"
              }
            />
            {/* <SectionItem title="Fulfillment SLA" value="3 Business Days" /> */}
          </Box>
        )}
        {(hasOrderDirectApproved || hasOrderDirectRejected) &&
          (supplier?.orderDirect?.[0]?.note ? (
            <Box>
              <SectionHeader title="Notes" />
              <Box
                sx={{
                  width: 422,
                  overflow: "overlay",
                }}
                display="flex"
                height="150px"
                px="24px"
                mt={1}
              >
                <Typography
                  sx={{
                    color: "#979797",
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {supplier.orderDirect[0].note ?? "-"}
                </Typography>
              </Box>
              {/* <StyledTextField
              multiline
              rows={9}
              sx={{ "& .MuiOutlinedInput-root": { height: "100%" } }}
            /> */}
            </Box>
          ) : null)}
      </Box>
    </Box>
  );
};

SummaryTab.propTypes = {
  supplier: object,
};

export default SummaryTab;

import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { object, bool, number, func } from "prop-types";
import { Controller } from "react-hook-form";
import { StyledTextField } from "simply-depo-ui-components";
import { TrashIcon } from "components/Icons";
import { StyledLabel } from "components";
import useStyles from "../../../../../../../../newStyles";
import { CANADA_PHONE_CODES, COUNTRY_PHONE_CODES } from "utils/constants";
import { ExpandMore } from "@mui/icons-material";
import NumberFormat from "react-number-format";
import { onPastePhone, setPhoneNumberMask } from "helpers/helpers";
import { useSelector } from "react-redux";

const ContactsItem = ({
  contact,
  isEdit,
  index,
  control,
  handleRemoveContact,
  handleChangeDefault,
  formField,
  setValue,
}) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const phoneCodeList =
    currentUser.country === "CA" ? CANADA_PHONE_CODES : COUNTRY_PHONE_CODES;

  const classes = useStyles();
  if (!contact) return <></>;
  return (
    <Grid
      key={contact.id}
      container
      height="59px"
      alignItems="center"
      gap="22px"
      width="unset"
      sx={{
        ml: isEdit ? "-9px" : "27px",
        mr: "20px",
        "&:not(:last-of-type)": {
          borderBottom: !isEdit && "1px solid #d5d9d9",
        },
        "& .MuiTypography-root": {
          fontSize: "15px",
        },
      }}
    >
      <Grid item xs={4}>
        {isEdit ? (
          <Box display="flex" alignItems="center">
            <Controller
              render={({ field }) => (
                <>
                  <Radio
                    checked={!!field.value}
                    {...field}
                    onChange={() => handleChangeDefault(index)}
                  />
                  <StyledLabel
                    fill={field.value ? "#47a06d" : "#D5D9D9"}
                    style={{
                      mr: "22px",
                      px: "7px",
                    }}
                  />
                </>
              )}
              name={`contacts.${index}.defaultContact`}
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  fullWidth
                  placeholder="Title"
                  size="large"
                  InputProps={{
                    className: classes.editInput,
                    endAdornment: !contact.defaultContact && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleRemoveContact(index)}>
                          <TrashIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={error?.message || ""}
                  {...field}
                />
              )}
              name={`contacts.${index}.role`}
              control={control}
            />
          </Box>
        ) : (
          <Box display="flex" gap="10px" alignItems="center">
            <Typography color="#979797">{contact.role} </Typography>
            {contact.defaultContact && <StyledLabel />}
          </Box>
        )}
      </Grid>
      <Grid item xs={2.4}>
        {isEdit ? (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                fullWidth
                placeholder="Full name"
                size="large"
                InputProps={{ className: classes.editInput }}
                error={error?.message || ""}
                {...field}
              />
            )}
            name={`contacts.${index}.name`}
            control={control}
          />
        ) : (
          <Typography>{contact.name}</Typography>
        )}
      </Grid>
      <Grid item xs={2.4}>
        {isEdit ? (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <NumberFormat
                customInput={StyledTextField}
                fullWidth
                format={formField?.countryPrefix?.format}
                mask="_"
                size="small"
                InputProps={{
                  style: {
                    height: "43px",
                    fontSize: "15px",
                    paddingLeft: 0,
                    borderRadius: "8px",
                  },
                  startAdornment: (
                    <FormControl>
                      <Controller
                        render={({ field }) => {
                          return (
                            <Select
                              sx={{
                                borderRadius: "0",
                                marginRight: 1,
                                "& .MuiSelect-select": {
                                  minWidth: "50px",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderWidth: "0 1px 0 0 !important",
                                  borderColor: "#D5D9D9 !important",
                                },
                              }}
                              size="small"
                              renderValue={() => field?.value?.phone_code}
                              IconComponent={(props) => (
                                <ExpandMore sx={{ fontSize: 20 }} {...props} />
                              )}
                              error={!!error?.message}
                              {...field}
                              value={JSON.stringify(field?.value)}
                            >
                              {phoneCodeList.map((code) => {
                                return (
                                  <MenuItem key={code.label} value={code}>
                                    {code.phone_code} {code.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          );
                        }}
                        // name="countryPrefix"
                        name={`contacts.${index}.countryPrefix`}
                        control={control}
                      />
                    </FormControl>
                  ),
                }}
                error={!!error?.message}
                {...field}
                autoComplete="new-password"
                onPaste={(e) => {
                  const prepareVal = (v) => {
                    if (/^\+[\d]+/.test(v)) {
                      return v.substring(2);
                    }
                    return v;
                  };

                  onPastePhone({
                    e,
                    type: `contacts.${index}.phone`,
                    setValue: (type, val) => setValue(type, prepareVal(val)),
                    currentValue: `contacts.${index}.phone`,
                  });
                }}
              />
              // <StyledTextField
              //   fullWidth
              //   placeholder="Phone number"
              //   size="large"
              //   InputProps={{ className: classes.editInput }}
              //   error={error?.message || ""}
              //   {...field}
              // />
            )}
            name={`contacts.${index}.phone`}
            control={control}
          />
        ) : (
          <Typography>
            {setPhoneNumberMask(
              `${contact?.countryPrefix?.phone_code}${contact.phone}`
            )}
          </Typography>
        )}
      </Grid>
      <Grid item xs={2.4}>
        {isEdit ? (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                fullWidth
                placeholder="Email"
                size="large"
                InputProps={{ className: classes.editInput }}
                error={error?.message || ""}
                {...field}
              />
            )}
            name={`contacts.${index}.email`}
            control={control}
          />
        ) : (
          <Typography noWrap>{contact.email}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

ContactsItem.propTypes = {
  contact: object,
  isEdit: bool,
  index: number,
  control: object,
  handleRemoveContact: func,
  handleChangeDefault: func,
  formField: object,
  setValue: func,
};

export default ContactsItem;

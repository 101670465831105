const ORDER_TYPES = {
  DIRECT: "DIRECT",
  THIRD_PARTY: "THIRD_PARTY",
};

export const CART_FORM = "create-order-form";

export const PAY_WITH = { TERMS: "terms", CREDIT: "credit" };

export const defaultValues = {
  type: ORDER_TYPES.DIRECT,
  customerId: "",
  contactId: "",
  salesId: null,
  paymentTermsId: null,
  appliedDiscountsType: "NONE",
  manufacturerDiscountIds: [],
  products: [],
  orderProducts: [],
  note: { text: "" },
  manualDeliveryFee: false,
  cardId: "",
  totalOrderDiscountAmount: 0,
  totalOrderDiscountType: "PERCENTAGE",
  notifyRepresentatives: true,
  fulfillBy: null,
  payWith: PAY_WITH.TERMS,
};

export const PAYMENT_ITEMS = [
  {
    name: "Pay with terms",
    value: PAY_WITH.TERMS,
  },
  {
    name: "Pay with credit",
    value: PAY_WITH.CREDIT,
  },
];

export const paymentFields = (hidePayWithCredit) => {
  return [
    {
      name: "Pay with terms",
      value: PAY_WITH.TERMS,
      show: true,
    },
    {
      name: "Pay with credit",
      value: PAY_WITH.CREDIT,
      show: !hidePayWithCredit,
    },
  ].filter(({ show }) => !!show);
};

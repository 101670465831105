import { useContext, useMemo, useState } from "react";
import { Box, IconButton, MenuItem, Typography } from "@mui/material";
import { ContactItem } from "./components";
import {
  AddAddressDialog,
  CardIconComponent,
  StyledButton,
  StyledSelect,
  StyledTooltip,
} from "components";
import { EmailIcon, OutlinedPlusIcon, PhoneIcon } from "components/Icons";
import { CartContext } from "pages/CartPage/CartPage";
import { Controller } from "react-hook-form";
import { cardMask } from "components/AddCardDialog/AddCardDialog.constants";
import { PAY_WITH } from "pages/CartPage/CartPage.constants";
import { setPhoneNumberMask } from "helpers/helpers";

export const ContactsBlock = () => {
  const [openBillingPopup, setOpenBillingPopup] = useState(false);

  const {
    cartData,
    billingInfo,
    shippingInfo,
    contacts,
    approvedPaymentTerms,
    control,
    errors,
    handleOpenContact,
    handleOpenCards,
    formField,
    selectedContact,
    setSelectedContact,
    matchOrderDirect,
    setValue,
    clearErrors,
    isAdvancePayment,
    draftsLoading,
  } = useContext(CartContext);

  const hidePayWithCredit = useMemo(() => {
    return !(
      !!matchOrderDirect &&
      !!matchOrderDirect?.distributor?.allowStoreCreditCardPayments
    );
  }, [matchOrderDirect]);

  return (
    <>
      <Box my={1} display="flex">
        <ContactItem
          title="Shipping address"
          item
          firstLine={shippingInfo.topLine}
          secondLine={shippingInfo.bottomLine}
          onClick={() => setOpenBillingPopup(true)}
        />
        <ContactItem
          title="Billing address"
          item
          firstLine={billingInfo.topLine}
          secondLine={billingInfo.bottomLine}
          onClick={() => setOpenBillingPopup(true)}
        />

        <ContactItem
          error={errors?.cardId?.message}
          loading={
            cartData.cardLoading || draftsLoading || cartData.loadingProducts
          }
          title="Payment method"
          item={!isAdvancePayment || !!cartData.card}
          firstLine={
            cartData.card && isAdvancePayment ? (
              <Box display="flex" alignItems="center" gap="10px" height="27px">
                Credit Card{" "}
                <CardIconComponent
                  type={
                    cardMask?.find(
                      ({ cardtype }) => cardtype === cartData.card.brand
                    )?.cardtype
                  }
                />
              </Box>
            ) : (
              <Controller
                render={({ field }) => (
                  <StyledSelect
                    height="30px"
                    formSx={{ my: "8px" }}
                    {...field}
                    onChange={(e) => {
                      setValue("payWith", e.target.value, {
                        shouldDirty: true,
                      });
                      if (e.target.value === "terms") {
                        setValue("cardId", "");
                        return clearErrors("cardId");
                      }
                      setValue("cardId", cartData?.card?.id || "");
                    }}
                  >
                    <MenuItem value={PAY_WITH.TERMS}>Pay with terms</MenuItem>
                    {hidePayWithCredit ? null : (
                      <MenuItem value={PAY_WITH.CREDIT}>
                        Pay with credit
                      </MenuItem>
                    )}
                  </StyledSelect>
                )}
                name="payWith"
                control={control}
              />
            )
          }
          secondLine={
            isAdvancePayment || formField.payWith === PAY_WITH.CREDIT
              ? !!cartData.card &&
                `****${cartData.card?.last4} | Exp ${cartData.card?.expMonth}/${cartData.card?.expYear}`
              : approvedPaymentTerms?.name
          }
          onClick={handleOpenCards}
          hasEdit={
            (isAdvancePayment || formField.payWith === PAY_WITH.CREDIT) &&
            !cartData.cardLoading
          }
        />
        <Box pl={1.5} width="270px" height="fit-content" position="relative">
          <Box
            sx={{
              position: "absolute",
              top: "5px",
              bottom: 0,
              left: 0,
              borderLeft: "1.5px solid #409A65",
            }}
          />
          <Typography fontSize={18} color="#363531" mb="3px">
            Delivery contact
          </Typography>
          {contacts?.length ? (
            <>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledSelect
                    height="30px"
                    fullWidth
                    fontSize="14px"
                    color={field.value ? "#707070" : "rgba(112, 112, 112, 0.5)"}
                    notched={false}
                    sx={{
                      background: "rgba(255, 255, 255, 0.7)",
                      borderRadius: "4px 4px 0 0 ",
                    }}
                    noErrorMessage
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          boxShadow: "none",
                          border: "1px solid #D5D9D9",
                          borderRadius: " 0 0 4px 4px",
                        },
                      },
                    }}
                    border="1px solid #D5D9D9 !important"
                    displayEmpty
                    error={error?.message || ""}
                    {...field}
                    onChange={(event) => {
                      field.onChange(event);
                      const selectedContact = contacts.find(
                        (contact) => contact.id === event.target.value
                      );
                      setSelectedContact(selectedContact);
                    }}
                  >
                    <MenuItem onClick={handleOpenContact}>
                      <OutlinedPlusIcon size={"13px"} />
                      <Typography
                        fontSize="14px"
                        fontWeight="400"
                        color="#42A57F"
                        mt="1px"
                        ml={1}
                      >
                        Add new
                      </Typography>
                    </MenuItem>
                    <MenuItem sx={{ display: "none" }} value="">
                      Select contact
                    </MenuItem>

                    {contacts.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                )}
                name="contactId"
                control={control}
              />
              <Box
                sx={{
                  mt: "20px",
                  height: "20px",
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <StyledTooltip
                  arrow
                  placement="top"
                  title={
                    selectedContact?.email
                      ? setPhoneNumberMask(selectedContact?.email)
                      : "No email available"
                  }
                >
                  <Box>
                    <IconButton
                      onClick={() =>
                        (window.location.href = `mailto:${selectedContact?.email}`)
                      }
                      disabled={!selectedContact?.email}
                    >
                      <EmailIcon />
                    </IconButton>
                  </Box>
                </StyledTooltip>
                <StyledTooltip
                  arrow
                  placement="top"
                  title={
                    selectedContact?.phone
                      ? setPhoneNumberMask(selectedContact?.phone)
                      : "No phone available"
                  }
                >
                  <Box>
                    <IconButton disabled={!selectedContact?.phone}>
                      <PhoneIcon />
                    </IconButton>
                  </Box>
                </StyledTooltip>
              </Box>
            </>
          ) : (
            <StyledButton
              startIcon={
                <OutlinedPlusIcon
                  color={errors?.contactId ? "#FF6254" : "#707070"}
                  size="14"
                />
              }
              label={
                <Box ml={1} component="span">
                  Delivery contact
                </Box>
              }
              fontSize="12px"
              variant="outlined"
              color={errors?.contactId ? "error" : "grey"}
              sx={{
                whiteSpace: "nowrap",
                width: "100%",
                justifyContent: "flex-start",
              }}
              onClick={handleOpenContact}
            />
          )}
        </Box>
      </Box>

      <AddAddressDialog
        isOpen={openBillingPopup}
        handleClose={() => setOpenBillingPopup(false)}
      />
      {/* <EditContactPopup
        switchContactView={switchContactView}
        handleClose={() => setSwitchContactView(false)}
      /> */}
      {/* <AddressesPopup
        isOpen={openBillingPopup}
        handleClose={() => setOpenBillingPopup(false)}
      /> */}
    </>
  );
};

import pluralize from "pluralize";
import moment from "moment";
import {
  CANADAS_STATE_NAMES,
  CANADA_PHONE_CODES,
  COUNTRY_PHONE_CODES,
  EMAIL_REGEXP,
  STATE_NAMES,
} from "utils/constants";

export const getFormattedDate = (dateStr, zone) => {
  return new Date(dateStr).toLocaleString("en-US", {
    timeZone: zone || "America/New_York",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const normalizeUppercaseString = (str) => {
  if (str)
    return `${str.slice(0, 1).toUpperCase()}${str.slice(1).toLowerCase()}`;
  return "";
};

export const separateNumWithComma = (num) => {
  if (num) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  }
  return num;
};

export const photoUrl = (fileName) => {
  if (!fileName) return null;
  const URL_REGEX =
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})|(blob:http:\/\/)/;
  const isUrlFirebase = URL_REGEX.test(fileName);

  return isUrlFirebase
    ? fileName
    : // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_GOOGLE_BUCKET}/${fileName}`;
};

export const bytesToSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const matchEmail = (str) => {
  return EMAIL_REGEXP.test(str);
};

export const formatAddress = (ad) => {
  return `${ad.split(", ")[0]}\n${ad.split(", ").slice(1).join(", ")}`;
};

export const uploadProgress = (progressEvent, setProgress) =>
  setProgress(() =>
    Math.round((progressEvent.loaded * 100) / progressEvent.total)
  );

export const sliceCountry = (str) => {
  if (!str) return "";
  return str.split(",").slice(0, -1).join(",");
};

export const normalizeSnakeCaseString = (str) => {
  if (!str) return;
  const splitStr = str.split("_");

  return splitStr.map((s) => normalizeUppercaseString(s)).join(" ");
};

export const normalizeCamelCaseString = (str) => {
  if (!str) return;
  const words = str.split(/(?=[A-Z])/);

  return words.join(" ");
};

export const containsEvery = (objects, array) => {
  return objects?.every(
    (obj) =>
      array.findIndex((item) => item.id === obj.id || item === obj.id) > -1
  );
};

export const setPhoneNumberMask = (num) => {
  if (!num) return "-";
  const n = num;
  if (/^\+/.test(n)) {
    return n.replace(/\+(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3 $4");
  } else {
    return n;
  }
};

export const onPastePhone = ({ e, type, setValue, currentValue }) => {
  e.preventDefault();

  const text = e.clipboardData.getData("Text");

  // eslint-disable-next-line no-useless-escape
  const cleanedNumber = text.replace(/[^\+\d]/g, "");

  if (cleanedNumber.startsWith("1") && cleanedNumber.length === 11) {
    if (setValue) {
      return setValue(type || "phone", `+${cleanedNumber}`);
    }
  }

  if (cleanedNumber.startsWith("+1") && currentValue === "+1") {
    if (setValue) {
      return setValue(type || "phone", cleanedNumber);
    }
  }

  if (setValue) setValue(type || "phone", text);
};

export const setWebsiteMask = (url) => {
  const DOMAIN_URL_REGEX =
    // eslint-disable-next-line no-useless-escape
    /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/gim;

  const domain = url.match(DOMAIN_URL_REGEX);
  const queryString = url.replace(DOMAIN_URL_REGEX, "");
  const domainName = domain?.length
    ? domain[0].replace(
        // eslint-disable-next-line no-useless-escape
        /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?/gim,
        ""
      )
    : "";

  if (!url) return;
  return {
    url,
    domain: domain?.length ? domain[0] : null,
    domainName,
    queryString,
  };
};

export const setCardName = (name) => {
  if (!name) return "";
  if (name === "visa") return "Visa";
  if (name === "mastercard") return "Mastercard";
  if (name === "amex") return "AmEx";
  return "Card";
};

export const getFormattedAddressInfo = (addressObj) => {
  const { appartement, street, city, state, zip } = addressObj;
  return {
    topLine: `${appartement || ""} ${street || ""}`,
    bottomLine: `${city}, ${state} ${zip || ""}`,
  };
};

export const updateState = (setState, newValues) => {
  setState((prev) => ({ ...prev, ...newValues }));
};

export const getEnvironment = (name) => {
  // eslint-disable-next-line no-undef
  const currentEnvironment = process.env.REACT_APP_ENVIRONMENT;
  return currentEnvironment === name;
};

const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

export const stringAvatar = (name, props) => {
  if (name)
    return {
      sx: {
        bgcolor: stringToColor(name),
        ...props,
      },
      children: name.split(" ")[1]
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : name[0],
    };
};

const startsWithHTTPorHTTPS = (str) => {
  const regex = /^(http|https):\/\//;
  return regex.test(str);
};

export const checkAndPrepareWebsiteForCustomer = (str) => {
  if (!str) return "";
  const checkStartString = startsWithHTTPorHTTPS(str);
  return checkStartString ? str : `http://${str}`;
};

export const getDraftCounter = (draftsList) => {
  const cartCount = draftsList.reduce(
    (prevSum, { data }) =>
      prevSum +
      data.productsData?.reduce(
        (prevQtySum, { quantity }) => prevQtySum + quantity,
        0
      ),
    0
  );

  return cartCount >= 100 ? "99+" : cartCount;
};

export const getPaymentDue = (createdAt, daysInvoices) => {
  const daysPassed = moment().diff(createdAt, "days");
  if (!daysInvoices && daysInvoices !== 0) return false;

  const dayDiff = daysPassed - daysInvoices;

  if (dayDiff > 0)
    return {
      text: `Overdue ${dayDiff} ${pluralize("day", dayDiff)}`,
      overdue: true,
    };
  const dayValue = dayDiff * -1;
  const calendar = { 0: "today", 1: "tomorrow" };
  const dayString = calendar[dayValue] || `in ${dayValue} days`;

  return { text: `Due ${dayString}`, overdue: false };
};

export const isCardExpired = (month, year) => {
  const cardDate = moment(`${year}.${month}`).format("yyyy.MM");

  return moment().diff(cardDate, "months") > 0;
};

export const checkAndPrepareText = (text) => {
  const arr = [];
  const options = [
    {
      symbol: ":",
      message: ": (colon)",
      regex: /[:]/,
    },
    {
      symbol: "\n",
      message: "\\n (new line symbol)",
      regex: /\n/,
    },
    {
      symbol: "\t",
      message: "\\t (tabulation symbol)",
      regex: /\t/,
    },
  ];

  options.forEach((option) => {
    if (option.regex.test(text)) {
      arr.push(option.message);
    }
  });

  return arr;
};

export const containsSpecialChars = (str) => {
  const regex = /[:\t\n]/;

  return regex.test(str);
};

export const getStates = (userCountry = "US") => {
  switch (userCountry) {
    case "US":
      return STATE_NAMES;
    case "CA":
      return CANADAS_STATE_NAMES;
    default:
      return STATE_NAMES;
  }
};

export const getPhoneCodes = (userCountry = "US") => {
  switch (userCountry) {
    case "US":
      return COUNTRY_PHONE_CODES;
    case "CA":
      return CANADA_PHONE_CODES;
    default:
      return COUNTRY_PHONE_CODES;
  }
};

import {
  AppBar,
  Badge,
  Button,
  Divider,
  Menu,
  MenuItem,
  MenuList,
  Tab,
  Tabs,
  Toolbar,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useState, useMemo, useRef, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import useStyles from "../../styles";
import { useDispatch, useSelector } from "react-redux";

import { BookIcon, BoardIcon } from "simply-depo-ui-components";
import StyledTooltip from "components/StyledTooltip/StyledTooltip";
import { NotificationsMenu } from "components/NotificationsMenu/NotificationsMenu";
import { successMiddle } from "helpers/notifications";
import { useShowProductsWithoutLogIn } from "helpers/hooks";
import { ConfirmContext } from "pages/MasterPage/MasterPage";
import { Logo } from "..";
import ContactUsBar from "../ContactUsBar";
import { PATH_NAMES } from "utils/constants";
import { CartIcon, ConversationsIcon } from "components/Icons";
import { removeAllTokens } from "helpers/auth";
import { deleteCurrentUser } from "redux/actions/auth";

export const DesktopHeader = () => {
  const navigate = useNavigate();
  const { drafts: draftsList, loading: draftsLoading } = useSelector(
    ({ drafts }) => drafts
  );

  const auth = useSelector(({ auth }) => auth);
  const currentUser = auth?.currentUser || {};

  const { orderDirect, _count } = currentUser || {};
  const { isShowProductsWithoutLogIn, distributor_name } =
    useShowProductsWithoutLogIn();
  const { confirmState } = useContext(ConfirmContext);
  const allOrderDirectsArePending = useMemo(
    () => orderDirect?.every(({ status }) => status === "PENDING"),
    [orderDirect]
  );

  const orderDirectRejected = useMemo(
    () => orderDirect?.length === 1 && orderDirect?.[0]?.status === "REJECTED",
    [orderDirect]
  );

  const hidePayWithCredit = useMemo(() => {
    const isEvenOneAllowStoreCreditCardPayments = orderDirect?.some(
      (direct) => {
        return direct?.distributor?.allowStoreCreditCardPayments;
      }
    );
    return !isEvenOneAllowStoreCreditCardPayments;
  }, [orderDirect]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isProductsPathname = useMemo(
    () => pathname.split("/")?.[1] === "products",
    [pathname]
  );

  const [accountOpen, setAccountOpen] = useState(false);
  const [directListOpen, setDirectListOpen] = useState(false);

  const accountRef = useRef();
  const orderDirectRef = useRef();

  const tabValue = useMemo(
    () =>
      PATH_NAMES.includes(pathname)
        ? pathname
        : isProductsPathname
        ? "/suppliers"
        : false,
    [isProductsPathname, pathname]
  );

  const handleSignOut = () => {
    navigate(
      orderDirect[0]?.distributor?.storeName
        ? `/login/${orderDirect[0]?.distributor?.storeName}`
        : "/login"
    );
    removeAllTokens();
    dispatch(deleteCurrentUser());
    window.location.reload();
  };

  const cartCount = useMemo(() => {
    return draftsList.reduce(
      (prevSum, { data }) =>
        prevSum +
        data.productsData?.reduce(
          (prevQtySum, { quantity }) => prevQtySum + quantity,
          0
        ),
      0
    );
  }, [draftsList]);

  const reduceCartCount = (count) => (count >= 100 ? "99+" : count);

  const handleClickSignIn = () => {
    navigate(
      isShowProductsWithoutLogIn ? `/login/${distributor_name}` : "/login"
    );
    window.location.reload();
  };

  return (
    <AppBar
      position="static"
      color="inherit"
      sx={{ height: "68px" }}
      className={classes.navAppBar}
    >
      {(orderDirect?.length === 1 || confirmState.supplier) && (
        <ContactUsBar supplierState={confirmState.supplier} />
      )}
      <Toolbar className={classes.navToolBar}>
        <Box
          className={classes.logoWrapper}
          component={isShowProductsWithoutLogIn ? "span" : NavLink}
          to="/"
        >
          <Logo />
        </Box>
        {isShowProductsWithoutLogIn ? (
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                fontSize: 15,
                fontWeight: 500,
                width: "124px",
                height: "33px",
              }}
              onClick={handleClickSignIn}
            >
              Sign in
            </Button>
          </Box>
        ) : (
          <>
            <Tabs
              sx={{ width: "100%", pl: 7 }}
              value={tabValue}
              className={classes.navTabs}
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              <Tab
                to="/"
                value="/"
                className={classes.navTab}
                component={NavLink}
                icon={<BookIcon color={tabValue === "/" ? "#3D3D3D" : ""} />}
                label={
                  <Typography fontSize={10} color="#A5A5A5">
                    products
                  </Typography>
                }
              />
              <Tab
                to="/orders"
                value="/orders"
                disabled={!_count?.orders}
                className={classes.navTab}
                component={NavLink}
                icon={
                  <BoardIcon color={tabValue === "/orders" ? "#3D3D3D" : ""} />
                }
                label={
                  <Typography fontSize={10} color="#A5A5A5">
                    orders
                  </Typography>
                }
              />
              {orderDirect?.length > 1 && (
                <Tab
                  to="/suppliers"
                  value="/suppliers"
                  className={classes.navTab}
                  component={NavLink}
                  icon={
                    <PersonIcon
                      style={{ marginBottom: 0 }}
                      sx={{
                        color:
                          tabValue === "/suppliers" ? "#3D3D3D" : "#A5A5A5",
                      }}
                    />
                  }
                  label={
                    <Typography fontSize={10} color="#A5A5A5">
                      suppliers
                    </Typography>
                  }
                />
              )}

              <Tab
                to="/cart"
                value="/cart"
                disabled={
                  _count?.orderDirect === 1 &&
                  orderDirect?.[0]?.status === "REJECTED"
                }
                sx={{ ml: "auto" }}
                className={classes.navTab}
                component={NavLink}
                onClick={(e) => {
                  if (allOrderDirectsArePending) {
                    e.preventDefault();
                    successMiddle("Pending for order direct request approval", {
                      position: "bottom-center",
                    });
                  }
                }}
                icon={
                  <Box position="relative">
                    {draftsLoading ? (
                      <CircularProgress
                        sx={{
                          position: "absolute",
                          left: "calc(50% - 4px)",
                          top: "calc(50% - 14px)",
                        }}
                        size="14px"
                      />
                    ) : (
                      <Typography
                        fontSize="12px"
                        sx={{
                          position: "absolute",
                          left: "calc(50% + 4px)",
                          top: "calc(50% - 8px)",
                          transform: "translate(-50%, -50%)",
                          color: "#409A65 !important",
                        }}
                      >
                        {" "}
                        {reduceCartCount(cartCount)}
                      </Typography>
                    )}
                    <CartIcon />{" "}
                  </Box>
                }
              />
              {/* <StyledTooltip
            title={"Coming soon"}
            placement="top"
            isTooltip
            arrow
            disableHoverListener={false}
            sx={{
              "& .MuiTooltip-arrow": {
                top: "-2px !important",
              },
              "& .MuiTooltip-tooltip": {
                top: "-27px !important",
              },
            }}
          >
            <Tab
              to="/notifications"
              value="/notifications"
              className={classes.navTab}
              // onClick={() => setDirectListOpen(true)}
              ref={orderDirectRef}
              icon={<NotificationsIcon />}
            />
          </StyledTooltip> */}

              <StyledTooltip
                title="Coming soon"
                placement="bottom"
                arrow
                modifiers={[{ name: "offset", options: { offset: [0, -14] } }]}
                disableHoverListener={false}
              >
                <Tab
                  to="/conversations"
                  value="/conversations"
                  className={classes.navTab}
                  icon={
                    <Badge className={classes.badge} color="error">
                      <ConversationsIcon />
                    </Badge>
                  }
                />
              </StyledTooltip>
            </Tabs>
          </>
        )}
        {isShowProductsWithoutLogIn ? null : (
          <>
            <NotificationsMenu
              anchorEl={orderDirectRef.current}
              open={directListOpen}
              handleClose={() => setDirectListOpen(false)}
            />
            <Divider
              flexItem
              orientation="vertical"
              sx={{ height: "24px", alignSelf: "unset" }}
            />
            <Box>
              <Button
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                ref={accountRef}
                sx={{ color: "#5F6267", ml: "10px", fontSize: "17px" }}
                onClick={() => setAccountOpen((prev) => !prev)}
                endIcon={
                  accountOpen ? (
                    <ExpandLessOutlinedIcon width="12px" />
                  ) : (
                    <ExpandMoreOutlinedIcon width="12px" />
                  )
                }
              >
                Account
              </Button>
              <Menu
                open={accountOpen}
                anchorEl={accountRef.current}
                onClose={() => setAccountOpen(false)}
              >
                <MenuList>
                  <MenuItem
                    onClick={() => setAccountOpen(false)}
                    className={classes.navTypography}
                    component={NavLink}
                    to="/profile"
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => setAccountOpen(false)}
                    className={classes.navTypography}
                    component={NavLink}
                    disabled={!_count?.orders}
                    to="/orders"
                  >
                    Orders
                  </MenuItem>
                  {hidePayWithCredit ? null : (
                    <MenuItem
                      onClick={(e) => {
                        setAccountOpen(false);
                        if (allOrderDirectsArePending) {
                          e.preventDefault();
                          successMiddle(
                            "Pending for order direct request approval",
                            {
                              position: "bottom-center",
                            }
                          );
                        }
                      }}
                      className={classes.navTypography}
                      component={NavLink}
                      disabled={orderDirectRejected}
                      to="/payments"
                    >
                      Payments
                    </MenuItem>
                  )}
                  <Divider
                    flexItem
                    orientation="horizontal"
                    sx={{ width: "50px", ml: "16px" }}
                  />
                  <MenuItem
                    className={classes.navTypography}
                    sx={{ mb: -1 }}
                    onClick={handleSignOut}
                  >
                    Sign Out
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

import { createContext, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import { Box, Stack } from "@mui/material";

import { Footer, HeaderBar, HelpDrawer, Loader } from "components";

import { authLoadingSelector, currentUserSelector } from "redux/selectors/auth";
import { getDraftsAction } from "redux/actions/drafts";
import UniversalConfirm from "components/UniversalConfirm/UniversalConfirm";
import { useShowProductsWithoutLogIn } from "helpers/hooks";
import { useAppWithoutLogIn } from "pages/ProductsPage/useAppWithoutLogIn";
import { useMediaDevice } from "hooks/useMediaDevice";
import { useSuppliers } from "pages/ProductsPage/useSuppliers";
import { selectedSupplierSelector } from "redux/selectors/distributors";
import RequestWholesale from "components/RequestWholesaleDialog/RequestWholesale";

const selector = createSelector(
  authLoadingSelector,
  currentUserSelector,
  selectedSupplierSelector,
  (authLoading, currentUser, selectedSupplier) => ({
    authLoading,
    currentUser,
    selectedSupplier,
  })
);
export const ConfirmContext = createContext({});
export const SuppliersContext = createContext({});

export const MasterPage = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { isMobile } = useMediaDevice();
  const { isShowProductsWithoutLogIn } = useShowProductsWithoutLogIn();

  const { authLoading } = useSelector(selector);
  const isLoading = useMemo(() => authLoading, [authLoading]);

  const [confirmState, setConfirmState] = useState({
    onConfirm: () => {},
    title: "",
    isOpen: false,
    cardStateItem: {},
    type: "dialog",
    text: "",
    subText: "",
    titleElement: null,
  });

  useEffect(() => {
    if (isShowProductsWithoutLogIn) return;
    dispatch(getDraftsAction());
  }, [dispatch, isShowProductsWithoutLogIn]);

  const appWithoutLogInState = useAppWithoutLogIn();

  const { suppliersState, getSortedSuppliersList } = useSuppliers({
    checkedSupplier: state?.filter?.supplier,
  });

  return (
    <ConfirmContext.Provider
      value={{ confirmState, setConfirmState, appWithoutLogInState }}
    >
      <SuppliersContext.Provider
        value={{ suppliersState, getSortedSuppliersList }}
      >
        <Stack sx={{ height: { xs: "100%", sm: "100vh" } }} direction="column">
          <HelpDrawer />
          <UniversalConfirm />
          <RequestWholesale />
          <Loader isLoading={isLoading} />
          <HeaderBar />

          <Stack
            sx={{
              flexGrow: 1,
              paddingLeft: {
                xs: !state?.product && "16px",
                sm: !state?.product && "24px",
              },
              paddingRight: {
                xs: !state?.product && "16px",
                sm: !state?.product && "24px",
              },
              position: "relative",
            }}
          >
            <Outlet />
          </Stack>
          {!isMobile && (
            <Box component="footer">
              <Footer />
            </Box>
          )}
        </Stack>
      </SuppliersContext.Provider>
    </ConfirmContext.Provider>
  );
};

import { createContext, useState } from "react";

import { DesktopCartPage, MobileCartPage } from "./components";

import { useCart } from "./CartPage.hooks";
import { useMediaDevice } from "hooks/useMediaDevice";

export const CartContext = createContext();

export const CartPage = () => {
  const { isMobile } = useMediaDevice();

  const {
    cartData,
    billingInfo,
    shippingInfo,
    contacts,
    approvedPaymentTerms,
    billingAddress,
    customerId,
    handleSubmitCart,
    control,
    handleAddNewContact,
    orderDirect,
    handleSelectDistributor,
    casesCount,
    handleSearchChange,
    handleConfirmCard,
    products,
    productsAvailableLessThanMinimum,
    handleCheckProduct,
    handleAddProducts,
    calculatedTotal,
    handleDecreaseQty,
    handleAddQty,
    handleRemoveOrderProduct,
    setQuantity,
    getOutOfStock,
    calculatedDelivery,
    grandTotal,
    errors,
    formField,
    draftsInitialLoaded,
    handleCloseContact,
    handleOpenContact,
    handleOpenCards,
    handleCloseCards,
    productFields,
    removeProducts,
    removeDraftCard,
    handleSetCardToCart,
    draftsLoading,
    customerDiscount,
    grandTotalWithDiscount,
    setErrorQty,
    disabledCreateOrderBtn,
    setValue,
    userProgressStep,
    handleIncreaseUserStep,
    selectedContact,
    setSelectedContact,
    matchOrderDirect,
    clearErrors,
    updateCartData,
    onDeleteContact,
    resetCardCart,
    isAdvancePayment,
  } = useCart();

  const [viewAllProductsDialogState, setViewAllProductsDialogState] = useState({
    open: false,
  });

  const handleOpenViewAllProductsDialog = () => {
    setViewAllProductsDialogState((prev) => ({ ...prev, open: true }));
  };

  const handleCloseViewAllProductsDialog = () => {
    setViewAllProductsDialogState((prev) => ({ ...prev, open: false }));
  };

  return (
    <CartContext.Provider
      value={{
        setValue,
        cartData,
        billingInfo,
        shippingInfo,
        contacts,
        approvedPaymentTerms,
        handleSubmitCart,
        control,
        handleAddNewContact,
        orderDirect,
        handleSelectDistributor,
        handleSearchChange,
        products,
        productsAvailableLessThanMinimum,
        handleCheckProduct,
        handleAddProducts,
        calculatedTotal,
        handleDecreaseQty,
        handleAddQty,
        casesCount,
        handleRemoveOrderProduct,
        setQuantity,
        getOutOfStock,
        calculatedDelivery,
        grandTotal,
        errors,
        formField,
        handleOpenContact,
        handleCloseContact,
        handleOpenCards,
        productFields,
        removeProducts,
        draftsLoading,
        customerDiscount,
        grandTotalWithDiscount,
        setErrorQty,
        disabledCreateOrderBtn,
        handleOpenViewAllProductsDialog,
        handleCloseViewAllProductsDialog,
        draftsInitialLoaded,
        viewAllProductsDialogState,
        handleCloseCards,
        customerId,
        billingAddress,
        handleConfirmCard,
        removeDraftCard,
        handleSetCardToCart,
        userProgressStep,
        handleIncreaseUserStep,
        selectedContact,
        setSelectedContact,
        clearErrors,
        matchOrderDirect,
        updateCartData,
        onDeleteContact,
        resetCardCart,
        isAdvancePayment,
      }}
    >
      {isMobile ? <MobileCartPage /> : <DesktopCartPage />}
    </CartContext.Provider>
  );
};

import { yupResolver } from "@hookform/resolvers/yup";
import { createSelector } from "@reduxjs/toolkit";
import {
  containsEvery,
  getFormattedAddressInfo,
  getPhoneCodes,
} from "helpers/helpers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSelector } from "redux/selectors/auth";
import { validationSchema } from "./CartPage.validations";
import { PAY_WITH, defaultValues } from "./CartPage.constants";
import { setCurrentUser, updateProfileAction } from "redux/actions/auth";
import { identity, pickBy } from "lodash";
import { useDebounce } from "simply-depo-ui-components";
import { getProductsService } from "services/products";
import { error, success } from "helpers/notifications";
import { createOrderService } from "services/orders";
import { getCartDraftDataService } from "services/drafts";
import {
  draftsInitialLoadedSelector,
  draftsListSelector,
  draftsLoadingSelector,
  userCartStepSelector,
} from "redux/selectors/drafts";
import {
  addDraftAction,
  deleteDraftAction,
  getDraftsAction,
  getDraftsList,
  selectedDistributorAction,
  setDraftsLoading,
  updateDraftsAction,
  userCartStepAction,
} from "redux/actions/drafts";
import { useNavigate } from "react-router-dom";
import { getCustomerCardByIdService } from "services/stripe";
import { getCurrentUser } from "services/account";
import { compact } from "lodash";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";
import { useMediaDevice } from "hooks/useMediaDevice";

const selector = createSelector(
  currentUserSelector,
  draftsListSelector,
  draftsLoadingSelector,
  draftsInitialLoadedSelector,
  userCartStepSelector,
  (
    currentUser,
    draftsList,
    draftsLoading,
    draftsInitialLoaded,
    userCartStep
  ) => ({
    currentUser,
    draftsList,
    draftsLoading,
    draftsInitialLoaded,
    userCartStep,
  })
);

export const useCart = () => {
  const dispatch = useDispatch();
  const { isMobile } = useMediaDevice();

  const {
    currentUser,
    draftsList,
    draftsLoading,
    draftsInitialLoaded,
    userCartStep,
  } = useSelector(selector);

  const { shippingAddress, billingAddress, contacts, orderDirect, _count } =
    useMemo(() => currentUser, [currentUser]);

  const [cartData, setCartData] = useState({
    loading: false,
    selectedDistributor: null,
    productsSearch: "",
    searchedProducts: [],
    loadingProducts: true,
    card: null,
    checkedProducts: [],
    isContactOpen: false,
    isCardOpen: false,
    isFirstLoad: true,
    cardLoading: false,
    isAlreadyReseted: false,
  });

  useEffect(() => {
    dispatch(getDraftsList([]));
    dispatch(getDraftsAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(selectedDistributorAction(cartData?.selectedDistributor));
  }, [cartData?.selectedDistributor, dispatch]);

  const [selectedContact, setSelectedContact] = useState(contacts[0]);

  const [errorQty, setErrorQty] = useState({});

  const disabledCreateOrderBtn = useMemo(
    () => !!compact([...Object.values(errorQty)])?.length,
    [errorQty]
  );

  const matchOrderDirect = useMemo(
    () =>
      orderDirect.find(
        ({ distributor }) => distributor.id === cartData.selectedDistributor?.id
      ),
    [cartData.selectedDistributor?.id, orderDirect]
  );

  const approvedPaymentTerms = useMemo(
    () => matchOrderDirect?.approvedPaymentTermsDuplicate,
    [matchOrderDirect]
  );

  const isAdvancePayment = useMemo(
    () => approvedPaymentTerms?.type === "ADVANCE_PAYMENT",
    [approvedPaymentTerms?.type]
  );

  const {
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(
      validationSchema({
        isAdvancePayment,
        isMobile,
        userCartStep,
      })
    ),
  });

  const formField = useWatch({ control });

  const updateCartData = (newValues) => {
    setCartData((prev) => ({ ...prev, ...newValues }));
  };

  const productsSearchDebounced = useDebounce(cartData.productsSearch, 500);

  const { fields: productFields, remove: removeProducts } = useFieldArray({
    control,
    name: "products",
  });

  const handleSearchProducts = useCallback(async () => {
    if (!productsSearchDebounced) {
      updateCartData({ searchedProducts: [] });
      return;
    }

    try {
      updateCartData({ loadingProducts: true });

      const { rows: searchedProducts } = await getProductsService({
        search: productsSearchDebounced,
      });
      updateCartData({ searchedProducts, loadingProducts: false });
    } catch (err) {
      error(err?.response?.data?.message);
      updateCartData({ loadingProducts: false });
    }
  }, [productsSearchDebounced]);

  useEffect(() => {
    handleSearchProducts();
  }, [handleSearchProducts]);

  const formFieldDebounced = useDebounce(formField, 500);

  const currentDraft = useMemo(
    () =>
      draftsList.find(
        ({ distributor }) => distributor.id === cartData.selectedDistributor?.id
      ),
    [cartData.selectedDistributor?.id, draftsList]
  );

  const handleUpdateErrorQty = useCallback((products, objOfObjs) => {
    const updatedObjOfObjs = { ...objOfObjs };

    const productIds = products.map((product) => product.id);

    Object.keys(updatedObjOfObjs).forEach((key) => {
      if (!productIds.includes(key)) {
        delete updatedObjOfObjs[key];
      }
    });

    return updatedObjOfObjs;
  }, []);

  const handleUpdateDraft = useCallback(async () => {
    if (!isDirty || cartData.loadingProducts) return;

    const { products, ...uploadDraft } = formFieldDebounced;

    const productsData = products
      ?.map((product) => {
        return {
          id: product.id,
          quantity: product.quantity,
          itemDiscountAmount: product.itemDiscountAmount,
          itemDiscountType: product.itemDiscountType,
          minOrderQTY: product.minOrderQTY,
          price: product.wholesalePrice,
        };
      })
      .filter((p) => p.id);

    if (productsData?.length) {
      const newErrorQty = handleUpdateErrorQty(productsData, errorQty);
      setErrorQty(newErrorQty);
    }

    if (currentDraft) {
      dispatch(
        updateDraftsAction(
          { ...uploadDraft, productsData },
          cartData.selectedDistributor?.id,
          currentDraft?.id
        )
      );
    } else {
      dispatch(
        addDraftAction(
          { ...uploadDraft, productsData },
          cartData.selectedDistributor?.id
        )
      );
    }
  }, [
    cartData.selectedDistributor?.id,
    cartData.loadingProducts,
    currentDraft,
    dispatch,
    errorQty,
    formFieldDebounced,
    handleUpdateErrorQty,
    isDirty,
  ]);

  useEffect(() => {
    handleUpdateDraft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced]);

  const handleSelectDistributor = useCallback(
    (selectedDistributor) => {
      setValue("payWith", defaultValues.payWith);
      setCartData((prev) => ({
        ...prev,
        selectedDistributor,
      }));
    },
    [setValue]
  );

  const handleSearchChange = useCallback((productsSearch) => {
    setCartData((prev) => ({ ...prev, productsSearch }));
  }, []);

  const shippingInfo = useMemo(
    () => getFormattedAddressInfo(shippingAddress),
    [shippingAddress]
  );

  const billingInfo = useMemo(
    () => getFormattedAddressInfo(billingAddress),
    [billingAddress]
  );

  const currentCustomerFromDistributor = useMemo(
    () => matchOrderDirect?.distributor?.customers[0],
    [matchOrderDirect?.distributor?.customers]
  );

  const customerId = useMemo(
    () => matchOrderDirect?.distributor?.customers[0]?.id,
    [matchOrderDirect]
  );

  const { products } = useMemo(() => formField, [formField]);

  const navigate = useNavigate();

  const setCardById = useCallback(
    async (id) => {
      if (!id) {
        updateCartData({ cardLoading: false });
        return;
      }
      try {
        updateCartData({ cardLoading: true });
        const card = await getCustomerCardByIdService(customerId, id);
        updateCartData({ card, cardLoading: false });
      } catch (err) {
        updateCartData({ cardLoading: false });
        error(err?.response?.data?.message);
      }
    },
    [customerId]
  );

  const fetchDraftedProducts = useCallback(async () => {
    if (draftsLoading) return;
    try {
      const { products: draftedProducts } = await getCartDraftDataService({
        productsData: JSON.stringify(
          currentDraft?.data?.productsData?.map(
            ({ id, quantity, itemDiscountAmount, itemDiscountType }) => ({
              id,
              quantity,
              itemDiscountAmount,
              itemDiscountType,
            })
          )
        ),
        distributor_id: cartData.selectedDistributor?.id,
      });

      return draftedProducts;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    }
  }, [
    cartData.selectedDistributor?.id,
    currentDraft?.data?.productsData,
    draftsLoading,
  ]);

  const resetCart = useCallback(async () => {
    if (
      (!cartData.selectedDistributor && !cartData.isFirstLoad) ||
      draftsLoading
    )
      return;

    if (cartData.isAlreadyReseted) return;

    updateCartData({ loadingProducts: true });

    const draftedProducts = await fetchDraftedProducts();

    const defaultContact = contacts.find(
      ({ defaultContact }) => defaultContact
    );

    const {
      data: {
        contactId: draftContact,
        cardId: draftCard,
        note: draftNote,
        notifyRepresentatives,
        payWith: draftPayWith,
      },
    } = currentDraft || { data: {} };

    const isActualContact = contacts?.find(({ id }) => id === draftContact);

    if (!isActualContact) {
      setSelectedContact(defaultContact || null);
    }

    reset({
      ...defaultValues,
      contactId: isActualContact
        ? draftContact || (defaultContact?.id ?? defaultValues.contactId)
        : defaultContact?.id ?? defaultValues.contactId,
      paymentTermsId: approvedPaymentTerms?.paymentTermsId,
      cardId: formField.cardId || draftCard || defaultValues.cardId,
      products: draftedProducts || [],
      note: draftNote?.text ? draftNote : { text: "" },
      notifyRepresentatives:
        notifyRepresentatives ?? defaultValues.notifyRepresentatives,
      salesId:
        currentCustomerFromDistributor?.assignedRepresentatives?.[0]
          ?.representative?.id || defaultValues.salesId,
      payWith: draftPayWith || defaultValues.payWith,
    });

    setCardById(draftCard);

    updateCartData({
      loadingProducts: draftsLoading ? true : false,
      isFirstLoad: false,
      isAlreadyReseted: true,
    });
  }, [
    approvedPaymentTerms?.paymentTermsId,
    cartData.isAlreadyReseted,
    cartData.isFirstLoad,
    cartData.selectedDistributor,
    contacts,
    currentCustomerFromDistributor?.assignedRepresentatives,
    currentDraft,
    draftsLoading,
    fetchDraftedProducts,
    formField.cardId,
    reset,
    setCardById,
  ]);

  useEffect(() => {
    if (cartData.selectedDistributor) {
      resetCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData.selectedDistributor?.id, cartData.isFirstLoad, draftsLoading]);

  const onSubmit = useCallback(
    async (data) => {
      dispatch(setDraftsLoading(true));
      const submitProducts = products.map((product) => ({
        id: product.id,
        quantity: product.quantity,
        manufacturerDiscountData: [],
        itemDiscountAmount: product.itemDiscountAmount || 0,
        itemDiscountType: product.itemDiscountType || "PERCENTAGE",
        // minOrderQTY: product.minOrderQTY,
        // productId: product.product?.id,
        // isNewAdded: product.isNewAdded,
      }));
      try {
        updateCartData({ loading: true });
        const distributor_id = cartData.selectedDistributor?.id;
        const { payWith } = data;
        delete data.payWith;
        const body = {
          ...data,
          customerId,
          products: submitProducts,
          cardId:
            approvedPaymentTerms?.type === "ADVANCE_PAYMENT" ||
            payWith === PAY_WITH.CREDIT
              ? data.cardId
              : undefined,
          paymentTermsId:
            approvedPaymentTerms?.type === "ADVANCE_PAYMENT" ||
            payWith === PAY_WITH.CREDIT
              ? undefined
              : data.paymentTermsId,
        };

        const res = await createOrderService({
          body,
          params: { distributor_id },
        });
        updateCartData({ loading: false });

        if (!_count?.orders) {
          const user = await getCurrentUser();
          dispatch(setCurrentUser(user));
        }

        dispatch(deleteDraftAction(currentDraft?.id, distributor_id));
        success("Order placed successfully!");
        navigate(`/orders/${res.id}`, {
          state: {
            orderNumber: res?.customId?.customId,
            distributor: res?.distributor,
            isConfirmationPage: true,
          },
        });
        dispatch(userCartStepAction(1));
      } catch (err) {
        updateCartData({ loading: false });
        const URL_REGEX = /is not available/;
        if (
          err?.response?.status === 400 &&
          URL_REGEX.test(err?.response?.data?.message)
        ) {
          const draftedProducts = await fetchDraftedProducts();
          reset({
            ...defaultValues,
            ...formField,
            products: draftedProducts || [],
          });
          return;
        }
        error(err?.response?.data?.error);
      } finally {
        dispatch(setDraftsLoading(false));
      }
    },
    [
      products,
      cartData.selectedDistributor?.id,
      customerId,
      approvedPaymentTerms?.type,
      _count?.orders,
      dispatch,
      currentDraft?.id,
      navigate,
      fetchDraftedProducts,
      reset,
      formField,
    ]
  );

  const handleSubmitCart = handleSubmit(onSubmit);

  const phoneCodeList = getPhoneCodes(currentUser.country);

  const handleAddNewContact = (newContact, callback) => {
    const updatedContact = {
      ...newContact,
      phone: newContact.phone
        ? `${phoneCodeList[0].phone_code}${newContact.phone
            .replace(/\D/g, "")
            .slice(1)}`
        : "",
    };

    const newContacts = [
      ...contacts.map(({ appCustomerId, ...c }) => {
        return updatedContact?.defaultContact
          ? { ...c, defaultContact: false }
          : c;
      }),
      updatedContact,
    ];
    const { id, baseUser, orderDirect, ...data } = {
      contacts: newContacts,
    };
    dispatch(
      updateProfileAction({
        data: pickBy(data, identity),
        onSuccess: (res) => {
          setValue(
            "contactId",
            res?.contacts?.find((c) => c?.name === updatedContact?.name)?.id ??
              res?.contacts?.[0]?.id,
            { shouldDirty: true }
          );
          setSelectedContact(
            res?.contacts?.find((c) => c?.name === updatedContact?.name) ??
              res?.contacts?.[0]
          );
          clearErrors("contactId");
          callback && callback();
        },
      })
    );
  };

  const onDeleteContact = (id) => {
    if (!id) return;
    const index = contacts.findIndex((c) => c.id === id);

    const preparedContacts = contacts.map(
      ({ appCustomerId, countryPrefix, ...c }) => c
    );

    preparedContacts.splice(index, 1);

    dispatch(
      updateProfileAction({
        data: { contacts: preparedContacts },
        onSuccess: () => {
          setSelectedContact(null);
          setValue("contactId", defaultValues.contactId, { shouldDirty: true });
        },
      })
    );
  };

  const handleExpandOnlyDistributor = useCallback(() => {
    if (orderDirect.length === 1) {
      setCartData((prev) => ({
        ...prev,
        selectedDistributor: orderDirect[0].distributor,
      }));
    }
  }, [orderDirect]);

  useEffect(handleExpandOnlyDistributor, [handleExpandOnlyDistributor]);

  const handleConfirmCard = (card) => {
    updateCartData({ card, isCardOpen: false });
    setValue("cardId", card?.id, { shouldValidate: true, shouldDirty: true });
  };

  const shouldForceCard = useMemo(
    () =>
      isAdvancePayment &&
      cartData?.card &&
      formField.payWith === PAY_WITH.TERMS,
    [cartData?.card, formField.payWith, isAdvancePayment]
  );

  useEffect(() => {
    if (shouldForceCard) {
      setValue("payWith", PAY_WITH.CREDIT, {
        shouldDirty: true,
      });
      setValue("cardId", cartData?.card?.id, {
        shouldDirty: true,
      });
    }
  }, [shouldForceCard, setValue, cartData?.card?.id]);

  const casesCount = products.reduce(
    (prevSum, prod) => prevSum + prod.quantity,
    0
  );

  const calcAvailable = useCallback((product) => {
    const onHand = product?.inventory?.onHand;
    const allocated = product?.inventory?.allocated;

    return onHand - allocated;
  }, []);

  const productsAvailableLessThanMinimum = useCallback(
    (product) => {
      const available = calcAvailable(product);
      if (product?.sellingOutOfStock) return false;

      return available < product?.minOrderQTY;
    },
    [calcAvailable]
  );

  const setCheckedProducts = useCallback((checkedProducts) => {
    updateCartData({ checkedProducts });
  }, []);

  const handleCheckProduct = useCallback(
    (product) => {
      const { checkedProducts } = cartData;
      if (product.isMultiple) {
        const filteredChildren = product.childProducts
          .filter(
            (childProduct) =>
              !products?.some(
                (prod) =>
                  prod?.id === childProduct?.id ||
                  prod?.product?.id === childProduct?.id
              )
          )
          .filter((prod) => {
            if (product?.type === PRODUCT_TYPE_INVENTORY.non_inventory)
              return true;

            return (
              ((!product.sellingOutOfStock &&
                prod.inventory?.onHand - prod.inventory?.allocated > 0) ||
                product.sellingOutOfStock) &&
              !productsAvailableLessThanMinimum(prod)
            );
          });

        if (containsEvery(filteredChildren, checkedProducts)) {
          const newChecked = [...checkedProducts];
          product.childProducts.forEach((childProduct) => {
            const index = newChecked.findIndex(
              (checkedCustomer) => checkedCustomer.id === childProduct.id
            );
            newChecked.splice(index, 1);
          });
          return setCheckedProducts([...newChecked]);
        }

        const addProducts = filteredChildren.map((childProduct) => {
          return {
            ...childProduct,
            quantity: childProduct.minOrderQTY || 1,
            parentProduct: product,
            itemDiscountType: "PERCENTAGE",
            itemDiscountAmount: 0,
            isNewAdded: true,
          };
        });

        const uniqueProducts = [
          ...new Map(
            [...checkedProducts, ...addProducts].map((item) => [
              item["id"],
              item,
            ])
          ).values(),
        ];

        return setCheckedProducts(uniqueProducts);
      }
      const index = checkedProducts.findIndex((item) => item.id === product.id);
      if (index > -1)
        return setCheckedProducts(
          checkedProducts.filter((item) => item.id !== product.id)
        );
      setCheckedProducts([
        ...checkedProducts,
        { ...product, quantity: product.minOrderQTY || 1 },
      ]);
    },
    [cartData, setCheckedProducts, products, productsAvailableLessThanMinimum]
  );

  const handleAddProducts = useCallback(() => {
    const { checkedProducts } = cartData;
    setCheckedProducts([]);
    // clearErrors("products");
    if (products)
      return setValue("products", [...products, ...checkedProducts], {
        shouldDirty: true,
      });
    setValue("products", [...checkedProducts], { shouldDirty: true });
  }, [cartData, products, setCheckedProducts, setValue]);

  const calculatedTotal = products?.reduce(
    (prevSum, currProd) =>
      prevSum +
      (currProd.wholesalePrice || currProd.price || 0) * currProd.quantity,
    0
  );

  const handleDecreaseQty = useCallback(
    (index, qty) => {
      if (qty <= 1) return;
      setValue(`products.${index}.quantity`, qty - 1, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [setValue]
  );

  const handleAddQty = useCallback(
    (index, qty) => {
      setValue(`products.${index}.quantity`, qty + 1, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [setValue]
  );

  const handleRemoveOrderProduct = useCallback(
    (prod) => {
      return setValue(
        "products",
        products.filter((product) => product.id !== prod.id),
        { shouldDirty: true }
      );
    },
    [products, setValue]
  );

  const setQuantity = (e, product, outOfStock, index) => {
    const val = parseInt(e.target.value, 10);
    if ((outOfStock && val > product.quantity) || val < product.totalDelivered)
      return;
    setValue(
      `products.${index}.quantity`,
      e.target.value > 0
        ? parseInt(e.target.value, 10)
        : product?.minOrderQTY
        ? product?.minOrderQTY
        : 1,
      { shouldDirty: true, shouldValidate: true }
    );
  };

  const calculateAvailable = (onHand, allocated = 0) => {
    return Number(onHand) - Number(allocated);
  };

  const getOutOfStock = (product, index) => {
    const available = calculateAvailable(
      product.inventory?.onHand || product.product?.inventory?.onHand,
      product.inventory?.allocated || product.product?.inventory?.allocated
    );
    const qty = formField?.products?.[index]?.quantity - product.quantity;
    // const editOrderCondition = orderId ? product.isNewAdded : true;
    return (
      // editOrderCondition &&
      !product?.sellingOutOfStock &&
      !product?.product?.sellingOutOfStock &&
      !product?.parentProduct?.sellingOutOfStock &&
      !product?.product?.parentProduct?.sellingOutOfStock &&
      available - qty <= 0
    );
  };

  const deliveryAmount = useMemo(() => {
    const selectedDistributor = cartData.selectedDistributor;
    const { deliveryFeeType, deliveryFeeAmount } = selectedDistributor || {};
    return deliveryFeeType === "PERCENTAGE"
      ? (deliveryFeeAmount / 100) * calculatedTotal
      : deliveryFeeAmount;
  }, [calculatedTotal, cartData.selectedDistributor]);

  const calculatedDelivery = useMemo(() => {
    const selectedDistributor = cartData.selectedDistributor;
    if (!selectedDistributor?.deliveryFeePriceCap) return deliveryAmount;
    return calculatedTotal >= selectedDistributor.deliveryFeePriceCapAmount
      ? 0
      : deliveryAmount;
  }, [cartData.selectedDistributor, deliveryAmount, calculatedTotal]);

  const grandTotal = useMemo(
    () => calculatedTotal + calculatedDelivery,
    [calculatedDelivery, calculatedTotal]
  );

  const customerDiscount = useMemo(
    () => (grandTotal * currentCustomerFromDistributor?.percentDiscount) / 100,
    [currentCustomerFromDistributor?.percentDiscount, grandTotal]
  );

  const grandTotalWithDiscount = useMemo(
    () => grandTotal - customerDiscount,
    [customerDiscount, grandTotal]
  );

  const handleCloseContact = () => {
    updateCartData({ isContactOpen: false });
  };

  const handleOpenContact = () => {
    updateCartData({ isContactOpen: true });
  };

  const handleOpenCards = () => {
    updateCartData({ isCardOpen: true });
  };

  const handleCloseCards = () => {
    updateCartData({ isCardOpen: false });
  };

  const removeDraftCard = (id) => {
    if (id === formField.cardId) {
      setValue("cardId", defaultValues.cardId, {
        shouldDirty: true,
        shouldValidate: true,
      });
      updateCartData({ card: null });
    }
  };

  const resetCardCart = () => {
    setValue("cardId", defaultValues.cardId, {
      shouldDirty: true,
      shouldValidate: true,
    });
    updateCartData({ card: null });
  };

  const handleSetCardToCart = useCallback(
    (card, config) => {
      updateCartData({ card });
      setValue("cardId", card?.id, {
        shouldValidate: true,
        shouldDirty: config?.shouldDirty ?? true,
      });
    },
    [setValue]
  );

  const handleIncreaseUserStep = () => {
    const { selectedDistributor } = cartData;

    if (!products.length) {
      return setError("products", {
        type: "productsValidation",
        message: "At least 1 product is required",
      });
    }

    if (
      selectedDistributor?.minimumOrderValue &&
      calculatedTotal < selectedDistributor?.minimumOrderValueAmount
    ) {
      return setError("moqField", {
        type: "warning",
        message: `To place an order, $${selectedDistributor?.minimumOrderValueAmount} minimum spend required`,
      });
    }

    dispatch(userCartStepAction(userCartStep + 1));

    if (userCartStep + 1 >= 3) {
      handleSubmitCart();
    }
  };

  useEffect(() => {
    if (errors.moqField) {
      const { selectedDistributor } = cartData;
      if (
        selectedDistributor?.minimumOrderValue &&
        calculatedTotal >= selectedDistributor?.minimumOrderValueAmount
      ) {
        return clearErrors("moqField");
      }
    }
  }, [calculatedTotal, cartData, clearErrors, errors.moqField]);

  return {
    cartData,
    shippingInfo,
    billingInfo,
    contacts,
    approvedPaymentTerms,
    billingAddress,
    customerId,
    distributorId: orderDirect?.[0]?.distributor?.id,
    control,
    formField,
    handleSubmitCart,
    handleAddNewContact,
    orderDirect,
    handleSelectDistributor,
    casesCount,
    handleSearchChange,
    handleConfirmCard,
    products,
    productsAvailableLessThanMinimum,
    handleCheckProduct,
    handleAddProducts,
    calculatedTotal,
    handleDecreaseQty,
    handleAddQty,
    handleRemoveOrderProduct,
    setQuantity,
    getOutOfStock,
    calculatedDelivery,
    grandTotal,
    errors,
    updateCartData,
    draftsInitialLoaded,
    handleCloseContact,
    handleOpenContact,
    handleOpenCards,
    handleCloseCards,
    removeProducts,
    productFields,
    removeDraftCard,
    handleSetCardToCart,
    draftsLoading,
    customerDiscount,
    grandTotalWithDiscount,
    setErrorQty,
    disabledCreateOrderBtn,
    setValue,
    userProgressStep: userCartStep,
    handleIncreaseUserStep,
    selectedContact,
    setSelectedContact,
    clearErrors,
    reset,
    matchOrderDirect,
    onDeleteContact,
    resetCardCart,
    phoneCodeList,
    isAdvancePayment,
  };
};

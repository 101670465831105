import { useSelector } from "react-redux";

import { getDraftCounter } from "helpers/helpers";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useMemo } from "react";

export const useCartCounter = () => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const { drafts: draftsList } = useSelector(({ drafts }) => drafts);

  const { orderDirect, _count } = currentUser || {};

  const { selectedSupplier, hasMultipleSuppliers } = useCurrentUser();

  const getDraftForMultipleSuppliers = () => {
    if (selectedSupplier)
      return draftsList.filter(
        (draft) => draft.distributor.id === selectedSupplier.id
      );
    return [];
  };

  const currentDraft = hasMultipleSuppliers
    ? getDraftForMultipleSuppliers()
    : draftsList;

  const cartCounter = getDraftCounter(currentDraft);

  const firstOrderDirectStatus = orderDirect?.[0]?.status === "REJECTED";

  const getCurrentOrderDirect = () => {
    if (currentUser?.orderDirect?.length === 1) return orderDirect;

    const currentOrderDirect = currentUser?.orderDirect?.filter((sup) => {
      return sup?.distributor?.id === selectedSupplier?.id;
    });

    return currentOrderDirect;
  };

  const currentOrderDirect = getCurrentOrderDirect();

  const handleDisabledMultipleSuppliers = () => {
    if (!currentUser?.orderDirect?.length > 1) return firstOrderDirectStatus;

    if (currentOrderDirect?.length)
      return currentOrderDirect?.[0]?.status === "REJECTED";

    return true;
  };

  const showRejectScreen = useMemo(
    () => orderDirect?.length === 1 && orderDirect[0]?.status === "REJECTED",
    [orderDirect]
  );

  const disabledCartBtn =
    _count?.orderDirect === 1
      ? firstOrderDirectStatus
      : handleDisabledMultipleSuppliers();

  return { cartCounter, disabledCartBtn, currentOrderDirect, showRejectScreen };
};
